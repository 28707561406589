<template>
  <div class="demo" :style="{'background-color': backgroundColor}">

    <!-- B3 container -->
    <div ref="B3ContainerContainer" style="position:absolute; top:0; bottom:0; left:0; right:0; opacity:0;" class="fades">
      <div id="B3-container" style="position:absolute; top:0; bottom:0; left:0; right:0;">
      </div>
    </div>

    <!-- Notes -->
    <transition name="fade">
      <div v-if="showTopNote" class="pos-abs t0 l0 r0 my-text-container theme-font text-center" style="color:white; font-size:32px; margin-top:64px; text-shadow: 2px 2px 4px hsla(0, 0%, 0%, 0.6);">
        <span>Swipe box to unwrap!</span>
      </div>
    </transition>
    <div class="pos-abs t0 l0 r0 my-text-container theme-font text-center" style="color:white; font-size:18px; margin-top:8px; opacity:0.3;">
      <span>Your recipient will receive this via email!</span>
    </div>

    <!-- Open gift button -->
    <div ref="buttonDoneContainer" style="position:absolute; bottom:50px; left:0; right:0; text-align:center; display:none;" class="scale-transition scale-out">
      <!-- to="/" -->
      <v-btn
        dark class="btn xl" color="blue darken-3"
        @click="contentActive=true"
      >
        Open Gift
      </v-btn>
    </div>

    <!-- Content demo -->
    <transition name="fade">
      <div class="abs-container my-flex my-flex-center" style="background-color:#ededed;" v-if="contentActive">

        <div class="text-center">
          <img src="/images/image_icon.png" alt="content" style="width:240px;">
          <div class="theme-font" style="font-size:24px;">
            Your content here!
          </div>
        </div>

        <div style="position:absolute; top: 6px; left:0;" class="scale-transition">
          <v-btn dark class="btn button-corner" color="blue darken-1" to="/">Return Home</v-btn>
        </div>

      </div>
    </transition>

  </div>
</template>

<script>

  // OLD
  // @unused
  // /* global B3 */

  // NEW
  import Gx3d from '@/libs/gx3d'
  import gx3ds from '@/libs/gx3ds'

  import { toGx3d } from '@/libs/gx3d-translator'

  // NOW: use a pretty christmasy look for demo
  // overwrites
  let gx3dCustomization = {

    "ribbon": {
      "color": "#88353c"
    },
    "wrap": {
      "src": "/assets/wraps/chevron.jpg",
      "repeat": 4
    },
    "background": {
      "color": "#629dfd",
      "src": "/assets/textures/stripe.png",
      "opacity": 0.08
    },
    "back": {
      "src": "/assets/backs/radial_gradient.png",
      "opacity": 0.11,
      "color": 0
    },
    "front": {
      "src": null,
      "opacity": 0,
      "color": 0
    },

    card: {
      front: {
        color: '#000080',
        // NOTE: custom font doesn't work in gx3d without fout ~~~ because the font loader built into gx3d only loads "Gloria Hallelujah"
        // NOTE: I ghetto added a custom wait just for "Quicksand" into gx3d
        text: {
          text: 'Hello',
          fontFamily: 'Quicksand',
        },
      },
      inside: {
        text: {
          text: 'Thank you for using Swipewrap! 🎉',
        },
        signature: {
          color: '#641717',
          text: 'Sincerely,\nSwipewrap',
        },
      },
    },

  };

  export default {
    name:'demo',
    data() {
      return {
        showTopNote: true,
        contentActive: false,
      };
    },
    computed: {
      backgroundColor() {
        if(gx3dCustomization) {
          if(gx3dCustomization.background) {
            if(gx3dCustomization.background.color) {
              return gx3dCustomization.background.color;
            }
          }
        }
        if(Gx3d.DEFAULT_CUSTOMIZATION) {
          if(Gx3d.DEFAULT_CUSTOMIZATION.background) {
            if(Gx3d.DEFAULT_CUSTOMIZATION.background.color) {
              return Gx3d.DEFAULT_CUSTOMIZATION.background.color;
            }
          }
        }
        return 'Gray';
      },
    },
    async mounted() {

      // debug
      // console.log('router variables:');
      // https://router.vuejs.org/api/#component-injected-properties
      // console.log(this.$route);
      // console.log(this.$router);

      // debug
      // console.log(Gx3d.DEFAULT_CUSTOMIZATION);

      // OLD
      // B3.setAllSettings(settings);
      // B3.setLoadedCallback(function() {
      //   this.$refs.B3ContainerContainer.style.opacity = '1';
      // }.bind(this));
      // B3.setFinishedCallback(function() {
      //   this.$refs.buttonDoneContainer.classList.remove('scale-out');
      // }.bind(this));
      // B3.startInsideElement("B3-container");
      // B3.useSlowSpin(true);

      // NEW
      // grab instance
      const gx3d = gx3ds.main.instance;
      await gx3d.onLoaded();
      // configure
      gx3d.reset();
      gx3d.setManualUpdate(false);
      gx3d.setSlowSpin(true);
      gx3d.startInsideElement('B3-container');
      gx3d.pushCustomizations(Gx3d.DEFAULT_CUSTOMIZATION);
      gx3d.pushCustomizations(toGx3d({}));
      gx3d.pushCustomizations(gx3dCustomization);
      await gx3d.processCustomization();
      gx3d.reset();
      this.$refs.B3ContainerContainer.style.opacity = '1';

      // DEBUG
      // gx3d.gotoInside();

      // prevent done button flashing
      gx3d.setFinishedCallback(() => {

        setTimeout(() => {
          this.$refs.buttonDoneContainer.style.display = null;
          // show done button
          setTimeout(() => {
            this.$refs.buttonDoneContainer.classList.remove('scale-out');
          }, 500);
        }, 500);

      });

      window.addEventListener('mousedown', this.onWindowClick);
      window.addEventListener('touchstart', this.onWindowClick);
    },
    beforeDestroy() {

      // return instance
      const gx3d = gx3ds.main.instance;
      gx3d.startInsideElement(gx3ds.main.home);
      gx3d.setManualUpdate(true);
      gx3d.setFinishedCallback(()=>{});

      // TOM - ghetto force card font back to "Gloria Hallelujah"
      gx3d.pushCustomizations({ card: { front: { text: { fontFamily: 'Gloria Hallelujah' } } } });
      gx3d.processCustomization();


      window.removeEventListener('mousedown', this.onWindowClick);
      window.addEventListener('touchstart', this.onWindowClick);
    },
    methods: {
      onWindowClick() {
        this.showTopNote = false;
      },
    },
  };
</script>

<style scoped>

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .button-corner {
    padding: 6px;
    padding-left: 34px;
    padding-right: 34px;
    font-size: 22px;
    font-weight: 300;
    height: inherit;
    line-height: inherit;
    border-radius: 10px;
  }

  .btn.xl {
    /* padding: 6px;
    padding-left: 34px;
    padding-right: 34px;
    font-size: 22px; */
    padding: 12px;
    padding-left: 50px;
    padding-right: 50px;
    font-size: 50px;
    font-weight: 300;
    height: inherit;
    line-height: inherit;
    border-radius: 10px;
  }

  .scale-transition{
      -webkit-transition:-webkit-transform 0.3s cubic-bezier(0.53, 0.01, 0.36, 1.63) !important;
      transition:-webkit-transform 0.3s cubic-bezier(0.53, 0.01, 0.36, 1.63) !important;
      transition:transform 0.3s cubic-bezier(0.53, 0.01, 0.36, 1.63) !important;
      transition:transform 0.3s cubic-bezier(0.53, 0.01, 0.36, 1.63), -webkit-transform 0.3s cubic-bezier(0.53, 0.01, 0.36, 1.63) !important;
  }
  .scale-transition.scale-out{
      -webkit-transform:scale(0);
      transform:scale(0);
      -webkit-transition:-webkit-transform .2s !important;
      transition:-webkit-transform .2s !important;
      transition:transform .2s !important;
      transition:transform .2s, -webkit-transform .2s !important;
  }

  .fades {
    -webkit-transition: opacity 0.3s cubic-bezier(.25,.8,.50,1);
    -moz-transition: opacity 0.3s cubic-bezier(.25,.8,.50,1);
    -o-transition: opacity 0.3s cubic-bezier(.25,.8,.50,1);
    transition: opacity 0.3s cubic-bezier(.25,.8,.50,1);
  }

  .demo {
    position: fixed;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }

  /* mobile */
  @media only screen and (max-width: 700px) {

    .button-corner {
      padding: 4px;
      padding-left: 24px;
      padding-right: 24px;
      font-size: 18px;
    }

    .btn.xl {
      padding: 12px;
      padding-left: 40px;
      padding-right: 40px;
      font-size: 32px;
      font-weight: 300;
      height: inherit;
      line-height: inherit;
      border-radius: 10px;
    }

    #modal-token .modal-content {
      padding: 12px !important;
      padding-top: 0px !important;
    }

  }

</style>
